import React from 'react'
import './Cognition.css'

import image1 from '../images/Group 78.png'
import image2 from '../images/t (5) 1.png'
import image3 from '../images/Image 17-3-21 at 5 1.png'
import image4 from '../images/Vector (1).png'
import image5 from '../images/IMG_1406-min__1_-compressed-removebg-preview 1.png'
import image6 from '../images/Vector (2).png'
import image7 from '../images/t (7) 1.png'
import image8 from '../images/Triangle.png'
import image9 from '../images/Yoga.png'

const Cognition: React.FC = () => {
    return (
        <>
            <div className='ccontainer1'>
                <div className='content'>

                    <h1>Strengthening Logic & Mind power</h1>

                    <p>Chess requires a wide range of cognitive abilities.Apart from engaging in chess, there are many other ways to enhance these skills. </p>
                    <p>Our training program also includes guiding students in solving various puzzles like the Rubik's Cube, slider puzzles, and Sudoku.</p>
                    <p>Methods like Memory techniques and scheduling helps Students/professionals achieve high productivity with minimal effort.</p>

                </div>
                <div>
                    <img src={image1} alt="Comparision table graphic"></img>
                </div>

            </div>

            <div className='ccontainer2'>
                <div className='card'>
                    <h3>Rubik's Cube</h3>
                    <h5>To strengthen: Problem-solving</h5>
                    <p>We teach solving the following cubes</p>
                    <ul>
                        <li>1x3, 2x2, 3x3, 4x4 & 5x5 </li>
                        <li>Pyraminx Cube</li>
                    </ul>
                </div>


                <img src={image2} alt="Rubik's Cube"></img>

            </div>

            <div className='ccontainer3'>
                <div className='card'>

                    <h3>Slider</h3>
                    <h5>To strengthen: Planning & Imagination</h5>

                    <p>
                        Slider is a logical puzzle. By using planning and imagination, a player can solve it with minimal number of steps.
                    </p>

                </div>

                <div>
                    <img src={image3} alt="Slider puzzle"></img>
                </div>
            </div>

            <div className='ccontainer4'>
                <div className='card'>
                    <h3>Sudoku & Magic Squares</h3>
                    <h5>To strengthen: Relational Analysis</h5>
                </div>


                <img src={image4} alt="Magic Squares"></img>

            </div>

            <div className='ccontainer5'>
                <div className='card'>

                    <h3>Pop it</h3>
                    <h5>To strengthen: Methodological approach</h5>

                    <p>Pop-It is a simple logic based game. We teach the method to always win, or atleast draw.</p>

                </div>

                <div>
                    <img src={image5} alt="Pop it"></img>
                </div>
            </div>

            <div className='ccontainer6'>
                <div className='card'>

                    <h3>Scheduling</h3>
                    <h5>To strengthen: Planning</h5>
                    <p>We help Children/Adults to schedule their week as per their commitments and availability. A disciplined life style leads to maximum productivity.</p>
                </div>


                <img src={image6} alt="Schedule"></img>

            </div>

            <div className='ccontainer7'>
                <div className='card'>

                    <h3>Study/Memory techniques</h3>

                    <p>During studies, we ought to memorize some basic, fundamental information. Various memory techniques like Mnemonics, Linking, Pegging & Mind maps helps to memorize most kind of information. These methods makes memorization easy, long lasting and less time consuming.</p>

                </div>

                <div>
                    <img src={image7} alt="zigsaw puzzle"></img>
                </div>
            </div>

            <div className='ccontainer8'>
                <div className='card'>

                    <h3>Yoga</h3>
                    <p>Yoga enhances cognitive fitness by reducing stress, improving blood circulation to the brain, and cultivating mindfulness and mental clarity.We provide guidance for basic yoga practices.</p>
                </div>


                <img src={image8} alt="Yoga graphic"></img>

            </div>

            <div className='ccontainer9'>
                <div className='card'>

                    <h3>Meditation</h3>

                    <p>Meditation positively impacts cognitive fitness by reducing stress, improving attention, memory, and mental well-being.We offer guidance for beginners in practicing basic meditation techniques.</p>

                </div>

                <div>
                    <img src={image9} alt="Meditation graphic"></img>
                </div>
            </div>


        </>
    )
};

export default Cognition                        