import { configureStore } from '@reduxjs/toolkit';
import {chessSlice} from '../Components/Features/chessSlice';
import {chessVSlice} from '../Components/Features/chessVSlice';
import {authSlice} from '../Components/Features/authSlice';


export const store = configureStore({
  reducer: { 
    image : chessSlice.reducer,
    video : chessVSlice.reducer,
    auth : authSlice.reducer
  }
});

