import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import chessVService from './ChessVService'


const initialState = {
    video : [],
    isError : false,
    isVSuccess : false,
    isLoading : false,
    isvideo : false
}


export const getVideoName = createAsyncThunk('imageName/get', async()=>{
    try {
        return await chessVService.getVideoName()
    } catch (error) {
        return console.log(error)
    }

})

export const getVideo = createAsyncThunk('image/get', async(video : any)=>{
    try {
        return await chessVService.getVideo(video)
    } catch (error) {
        return console.log(error)
    }
})

export const createVideo = createAsyncThunk('image/create', async(data: { title: string, video: any, active: string })=>{

    try {
        return await chessVService.createVideo(data)
    } catch (error) {
        return console.log(error)   
    }
})

export const deleteVideo = createAsyncThunk('image/delete', async(id:any)=>{
    try {
        return await chessVService.deleteVideo(id)
    } catch (error) {
        return      console.log(error)
    }
})


export const chessVSlice = createSlice({
    name : 'video',
    initialState,
    reducers:{
        reset : (state) => initialState
    },
    extraReducers : (builder) =>{
        builder
        .addCase(createVideo.pending,(state )=>{
            state.isLoading = true;
        }).addCase(createVideo.fulfilled,(state, action)=>{
            state.isLoading = false;
            state.isVSuccess = true;
            state.video.push(action.payload as never );
        }).addCase(createVideo.rejected,(state, action )=>{
            state.isLoading = false;
            state.isError = true;
        })

        .addCase(getVideo.pending,(state )=>{
            state.isLoading = true;
        }).addCase(getVideo.fulfilled,(state, action )=>{
            state.isLoading = false;
            state.isVSuccess = true;
            state.video.push(action.payload as never );
        }).addCase(getVideo.rejected,(state)=>{
            state.isLoading = false;
            state.isError = true;
        })
        .addCase(deleteVideo.pending,(state : any )=>{
            state.isLoading = true;
        }).addCase(deleteVideo.fulfilled,(state :any, action :any )=>{
            state.isLoading = false;
            state.isVSuccess = true;
            state.video = state.image.filter((video:any) => video._id !== action.payload.id);
        }).addCase(deleteVideo.rejected,(state, action )=>{
            state.isLoading = false;
            state.isError = true;
            // state.videos = [];
        })
    }
})

export const {reset} = chessVSlice.actions
export default chessVSlice.reducer
