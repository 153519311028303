import React from 'react'
import './GettingStarted.css';
import image1 from '../images/Rectangle 93.png';
import image2 from '../images/7107D381-0BDD-4496-ACA0-E142D876F17D 1 (2).png';
import image3 from '../images/Image 1-5-23 at 7 1 (2).png';

const GettingStarted: React.FC = () => {
    return (
        <>
            <div className='gcontainer1'>

                <div>
                    <h3>Step-by-Step Guide to Online Chess Coaching</h3>
                </div>

                <div className='gcontent'>
                    <div className='gcard'>
                        <h4>Step 1: Start the Classes </h4>
                        <ul>
                            <li>Send an email to murali@lca.sg or WhatsApp to +6597224377. </li>
                            <li>Receive an email with all the details to proceed further. </li>
                            <li>Receive an email invoice every month upfront.</li>
                        </ul>
                    </div>

                    <div className='gcard'>
                        <h4>Step 2: Set up the environment</h4>
                        <ul>
                            <li>Choose the devices for online classes </li>
                            <li>Register required services </li>
                            <li>Setup the required software/apps</li>
                        </ul>
                    </div>

                    <div className='gcard'>
                        <h4>Step 3: Complete Payment </h4>
                        <ul>
                            <li>Pay the fee as per the invoice</li>
                            <li>Fee receipt confirmation email will be sent.</li>
                            <li>The classes Schedule is also mentioned in the email.</li>
                        </ul>
                    </div>

                    <div className='gcard'>
                        <h4>Step 4: Procure Material</h4>
                        <p>We follow Popular Steps-Method material. Please </p> <p>procure the material from </p> <p>
                            https://www.stappenmethode.nl/en/order-books-cds.php</p>
                    </div>
                </div>

            </div>

            <div className='gcontainer2'>
                <div className='gcard'>
                    <h5 style={{ textAlign: 'center' }}>Devices</h5>

                    <div>

                        <h6><img src={image1} alt="Online class screen"></img> Which device to use for the online classes?</h6>
                        <p>Any device that supports Zoom (Laptop, computer, mobile, ipad)</p>
                    </div>


                    <div>
                        <h6><img src={image1} alt="Exercise book"></img> Which Device is better?</h6>
                        <p>iPads are recommended. If Apple pencil is used, its more easy.</p>
                    </div>

                    <div>
                        <h6><img src={image1} alt="Online class"></img> How many devices are needed?</h6>
                        <p>It's advised to connect with two devices. One is to work with the Coach, another one is kept at 45 degrees for the coach to observe sitting posture.</p>
                    </div>
                </div>

                <div className='img1'>
                    <img src={image2} alt="Online class"></img>
                </div>

                <div className='img2'>
                    <img src={image3} alt="Online class"></img>
                </div>
            </div>

        </>
    )
}

export default GettingStarted