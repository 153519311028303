import React, { useState } from 'react';
import './Terms.css';
import image1 from '../images/Group 63.png';
import image2 from '../images/Group 62.png';

const Terms: React.FC = () => {
  const [activeDropdown, setActiveDropdown] = useState('');
  const [id, setId] = useState('1');



  const toggleDropdown = (dropdownId: string) => {

    console.log(dropdownId)

    if (id == dropdownId) {
      setActiveDropdown('')
      setId('')
    }
    else {
      setActiveDropdown(prevActiveDropdown => (prevActiveDropdown === dropdownId ? '' : dropdownId));
      setId(activeDropdown === dropdownId ? '' : dropdownId);
    }

  };

  return (
    <>
      <div className='tcontainer'>
        <h3>Terms & Conditions</h3>

        <div className='dropdown'>
          <div>
            <p>Payment</p>
          </div>
          <div>
            <button onClick={(e) => toggleDropdown('payment')}>
              {activeDropdown === 'payment' ? <img src={image1} alt='Collapse' /> : <img src={image2} alt='Expand' />}
            </button>
            {activeDropdown === 'payment' && (
              <ul className='content'>
                <li>Every month an invoice will be emailed upfront. </li> <li>
                  Fees have to be paid upfront against the invoice in Singapore dollars. </li> <li>
                  No refund of fees will be given. </li> <li>
                  If the classes are not taken, they can be taken in the next month. The amount of these classes will be offset from the next invoice. </li> <li>
                  Once the payment is received, payment confirmation will be sent, and the classes will be scheduled.</li>
              </ul>
            )}
          </div>
        </div>

        <hr />

        <div className='dropdown'>
          <div>
            <p>Class Cancellation</p>
          </div>
          <div>
            <button onClick={() => toggleDropdown('cancellation')}>
              {activeDropdown === 'cancellation' ? <img src={image1} alt='Collapse' /> : <img src={image2} alt='Expand' />}
            </button>
            {activeDropdown === 'cancellation' && (
              <ul className='content'>
                <li>No class cancellation fee will be charged if informed 24 hours ahead.</li>
                <li>
                  If the class was canceled by the coach or disrupted because of technical issues like net connectivity,
                  the missed time will be covered in the next session.
                </li>
                <li>If informed within 24 hours, a 50% fee will be charged.</li>
              </ul>
            )}
          </div>
        </div>

        <hr />

        <div className='dropdown'>
          <div>
            <p>Termination</p>
          </div>
          <div>
            <button onClick={() => toggleDropdown('termination')}>
              {activeDropdown === 'termination' ? <img src={image1} alt='Collapse' /> : <img src={image2} alt='Expand' />}
            </button>
            {activeDropdown === 'termination' && (
              <ul className='content'>
                <li>No contract will be signed, and either the student or coach can stop the classes at any time. </li>
                <li>
                  Unused classes amount will not be refunded.</li>
              </ul>
            )}
          </div>
        </div>

        <hr />

        <div className='dropdown'>
          <div>
            <p>No Hidden Charges</p>
          </div>
          <div>
            <button onClick={() => toggleDropdown('No Hidden Charges')}>
              {activeDropdown === 'No Hidden Charges' ? <img src={image1} alt='Collapse' /> : <img src={image2} alt='Expand' />}
            </button>
            {activeDropdown === 'No Hidden Charges' && (
              <ul className='content'>
                <li>No registration fee, slot holding fee, or termination fee will be charged.</li>
              </ul>
            )}
          </div>
        </div>

        <hr />

        <div className='dropdown'>
          <div>
            <p>Class Timing</p>
          </div>
          <div>
            <button onClick={() => toggleDropdown('Class Timing')}>
              {activeDropdown === 'Class Timing' ? <img src={image1} alt='Collapse' /> : <img src={image2} alt='Expand' />}
            </button>
            {activeDropdown === 'Class Timing' && (
              <ul className='content'>
                <li>The class timing will be mutually agreed upon by the coach and the student. </li> <li>
                  In case of any changes required, the coach will inform the student in advance.</li>
              </ul>
            )}
          </div>
        </div>

        <hr />
{/* title: string,
isActive: boolean,
body: string,
*/}
        <div className='dropdown'>
          <div>
            <p>Mode of Communication</p>
          </div>
          <div>
            <button onClick={() => toggleDropdown('Mode of Communication:')}>
              {activeDropdown === 'Mode of Communication:' ? <img src={image1} alt='Collapse' /> : <img src={image2} alt='Expand' />}
            </button>
            {activeDropdown === 'Mode of Communication:' && (
              <ul className='content'>
                <li>The classes will be conducted using online video conferencing platforms like Zoom. </li>
                <li>The coach will share the necessary details and links with the student prior to the class.</li>
              </ul>
            )}
          </div>
        </div>

        <hr />

        <div className='dropdown'>
          <div>
            <p>Class Material</p>
          </div>
          <div>
            <button onClick={() => toggleDropdown('Class Material')}>
              {activeDropdown === 'Class Material' ? <img src={image1} alt='Collapse' /> : <img src={image2} alt='Expand' />}
            </button>
            {activeDropdown === 'Class Material' && (
              <ul className='content'>
                <li>The coach will provide the necessary links and resources for the class. </li>
                <li>
                  Any material required will be communicated to the student in advance. The student has to procure the material</li>
              </ul>
            )}
          </div>
        </div>

        <hr />

        <div className='dropdown'>
          <div>
            <p>Class Conduct</p>
          </div>
          <div>
            <button onClick={() => toggleDropdown('Class Conduct')}>
              {activeDropdown === 'Class Conduct' ? <img src={image1} alt='Collapse' /> : <img src={image2} alt='Expand' />}
            </button>
            {activeDropdown === 'Class Conduct' && (
              <ul className='content'>
                <li>The student is expected to maintain proper decorum during the class. </li>
                <li>Any disruptive behavior will not be tolerated and can result in cancellation/termination of classes.</li>
              </ul>
            )}
          </div>
        </div>

        <hr />

        <div className='dropdown'>
          <div>
            <p>Class Progress</p>
          </div>
          <div>
            <button onClick={() => toggleDropdown('Class Progress')}>
              {activeDropdown === 'Class Progress' ? <img src={image1} alt='Collapse' /> : <img src={image2} alt='Expand' />}
            </button>
            {activeDropdown === 'Class Progress' && (
              <ul className='content'>
                <li>The coach will provide feedback and progress reports to the student after the completion of every Steps Method book </li>
                <li>The student is encouraged to provide feedback on the classes as well.</li>
              </ul>
            )}
          </div>
        </div>

        <hr />

        <div className='dropdown'>
          <div>
            <p>Liability</p>
          </div>
          <div>
            <button onClick={() => toggleDropdown('Liability')}>
              {activeDropdown === 'Liability' ? <img src={image1} alt='Collapse' /> : <img src={image2} alt='Expand' />}
            </button>
            {activeDropdown === 'Liability' && (
              <ul className='content'>
                <li>The coach will not be held responsible for any injuries or damages incurred during the class. </li>
                <li>The student is responsible for ensuring that they have the necessary equipment and a safe environment to attend the class.</li>
              </ul>
            )}
          </div>
        </div>
        <hr />

        <div className='dropdown'>
          <div>
            <p>Dispute Resolution</p>
          </div>
          <div>
            <button onClick={() => toggleDropdown('Dispute Resolution')}>
              {activeDropdown === 'Dispute Resolution' ? <img src={image1} alt='Collapse' /> : <img src={image2} alt='Expand' />}
            </button>
            {activeDropdown === 'Dispute Resolution' && (
              <ul className='content'>
                <li>Any disputes arising from the classes will be resolved through mutual discussion and negotiation. </li> <li>
                  In case of a deadlock, both parties can seek the assistance of a mediator or arbitrator in Singapore.</li>
              </ul>
            )}
          </div>
        </div>
        <hr />

        <div className='dropdown'>
          <div>
            <p>Confidentiality</p>
          </div>
          <div>
            <button onClick={() => toggleDropdown('Confidentiality')}>
              {activeDropdown === 'Confidentiality' ? <img src={image1} alt='Collapse' /> : <img src={image2} alt='Expand' />}
            </button>
            {activeDropdown === 'Confidentiality' && (
              <ul className='content'>
                <li>All information shared during the class will be kept confidential by both the coach and the student.</li>
                <li>No information will be disclosed to any third party without the consent of the other party.</li>
              </ul>
            )}
          </div>
        </div>
        <hr />


      </div>
    </>
  );
};

export default Terms;
