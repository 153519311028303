import React, { useEffect, useState } from 'react';
import { Menu, Drawer, Layout } from 'antd';

import type { MenuProps } from 'antd/es/menu';

import { MenuOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from './../images/logo.png';

const { Header } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
	label: React.ReactNode,
	key?: React.Key | null,
	children?: MenuItem[],
	onClick?: () => void,
	selected?: boolean
): MenuItem {
	return {
		key,
		children,
		label
	} as MenuItem;
}

const items: MenuItem[] = [
	getItem('HOME', 'home'),
	getItem('TOURNAMENT', 'tournament'),
	getItem('ANNUAL-MEET', 'annual'),
	getItem('METHODOLOGY', 'methodology'),
	getItem('3Ps APPROACH', 'approach'),
	getItem('HYBRID', 'hybrid'),
	getItem('COGNITION', 'cognition'),
	getItem('RESOURCES', 'Resources', [
		getItem('ACCOMPLISHMENTS', 'accomplishments'),
		getItem('Terms & Conditions', 'terms'),
		// getItem('Rates', 'rates'),
		// getItem('FAQs', 'faqs'),
		getItem('Contact Us', 'contactUs'),
		getItem('Getting Started', 'gettingStarted'),
		getItem('Setup', 'InitialSetup'),
		getItem('How to attend Classes', 'attendclasses')
	])
];

const Head: React.FC = () => {
	const [openMenu, setOpenMenu] = useState(false);
	const [selectedKey, setSelectedKey] = useState('home');

	const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent
	);

	const navigate = useNavigate();

	const location = useLocation();

	const path = location.pathname.substring(1);

	const handleSelect: MenuProps['onClick'] = (e) => {
		setSelectedKey(e.key);
		if (e.key === 'home') {
			navigate('/');
			setOpenMenu(false);
		} else {
			navigate('/' + e.key);
			setOpenMenu(false);
		}
	};
	console.log(selectedKey);
	useEffect(() => {
		if (path === '') {
			setSelectedKey('home');
		}
		setSelectedKey(path);
	}, [path]);

	return (
		<div>
			<>
				{isMobile ? (
					<div className="menuIcon">
						<div className="logo">
							<img src={Logo}  alt="Website Logo" height="25"></img>
						</div>

						<div>
							<MenuOutlined
								onClick={() => {
									setOpenMenu(true);
								}}
								style={{ fontSize: '24px', color: 'white' }}
							/>

							<Drawer
								open={openMenu}
								onClose={() => {
									setOpenMenu(false);
								}}
								closable={openMenu}
							>
								<Layout>
									<Header style={{ zIndex: 999 }}>
										<Menu
											mode="inline"
											defaultOpenKeys={['home']}
											items={items}
											selectedKeys={[selectedKey]}
											onSelect={handleSelect}
										></Menu>
									</Header>
								</Layout>
							</Drawer>
						</div>
					</div>
				) : (
					<div className="headerMenu">
						<Layout>
							<Header
								style={{ position: 'fixed', zIndex: 999, width: '100%', backgroundColor: 'black' }}
							>
								<img
									style={{
										position: 'absolute',
										left: '15px',
										top: '15px'
									}}
									src={Logo}  alt="Website Logo"
									height="40"
								></img>
								<Menu
									theme="dark"
									mode={'horizontal'}
									items={items}
									style={{
										position: 'absolute',
										right: '10px',
										width: '62%',
										backgroundColor: 'black'
									}}
									selectable={true}
									selectedKeys={[selectedKey]}
									onSelect={handleSelect}
								></Menu>
							</Header>
						</Layout>
					</div>
				)}
			</>
		</div>
	);
};

export default Head;
