import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import chessService from './chessService'


const initialState = {
    image : [],
    imageName : [],
    isError : false,
    isSuccess : false,
    isLoading : false,
    isImage : false
}


export const getImageName = createAsyncThunk('imageName/get', async()=>{
    try {
        return await chessService.getImageName()
    } catch (error) {
        return console.log(error)
    }
})


export const getImage = createAsyncThunk('image/get', async(image : any)=>{
    try {
        return await chessService.getImage(image)
    } catch (error) {
        return console.log(error)
    }
})

export const createImage = createAsyncThunk('image/create', async(data: { title: string, images: any, active: string })=>{

    try {
        return await chessService.createImage(data)
    } catch (error) {
        return console.log(error)   
    }
})

export const deleteImage = createAsyncThunk('image/delete', async(id:any)=>{
    try {
        return await chessService.deleteImage(id)
    } catch (error) {
        return      console.log(error)
    }
})

export const updateActive = createAsyncThunk('image/delete', async(id:any, active: any)=>{
    try {
        return await chessService.updateActive(id, active)
    } catch (error) {
        return      console.log(error)
    }
})


export const chessSlice = createSlice({
    name : 'image',
    initialState,
    reducers:{
        reset : (state) => initialState
    },
    extraReducers : (builder) =>{
        builder
        .addCase(createImage.pending,(state )=>{
            state.isLoading = true;
        }).addCase(createImage.fulfilled,(state, action)=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.image.push(action.payload as never );
        }).addCase(createImage.rejected,(state, action )=>{
            state.isLoading = false;
            state.isError = true;
        })

        .addCase(getImageName.pending,(state )=>{
            state.isLoading = true;
        }).addCase(getImageName.fulfilled,(state, action )=>{           
            state.isLoading = false;
            state.isSuccess = true;
            state.imageName = action.payload as any ;
        }).addCase(getImageName.rejected,(state)=>{
            state.isLoading = false;
            state.isError = true;
        })

        .addCase(getImage.pending,(state )=>{
            state.isLoading = true;
        }).addCase(getImage.fulfilled,(state, action )=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.image.push(action.payload as never );
        }).addCase(getImage.rejected,(state)=>{
            state.isLoading = false;
            state.isError = true;
        })
        .addCase(deleteImage.pending,(state : any )=>{
            state.isLoading = true;
        }).addCase(deleteImage.fulfilled,(state :any, action :any )=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.image = state.image.filter((image:any) => image._id !== action.payload.id);
        }).addCase(deleteImage.rejected,(state, action )=>{
            state.isLoading = false;
            state.isError = true;
            // state.videos = [];
        })
    }
})

export const {reset} = chessSlice.actions
export default chessSlice.reducer
