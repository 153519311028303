import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import authService from './authService'

// const  user = JSON.parse(localStorage.getItem('user') as any)

const  initialState = {
    user  : null,
    isError : false,
    isSuccess : false,
    isLoading : false
}

// export const register = createAsyncThunk('auth/register', async(user, thunkAPI)=>{

//     try {
//         return await authService.register(user)
//     } catch (error) {
//         const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//         return thunkAPI.rejectWithValue(message)        
//     }

// })

export const login = createAsyncThunk('auth/login', async(user : any)=>{

    try {        
        return await authService.login(user)
    } catch (error) {
        return console.log(error)       
    }

})

export const authSlice = createSlice({
    name : 'auth',
    initialState,
    reducers:{
        reset : (state) => {
            state.isError = false;
            state.isLoading = false;
            state.isSuccess = false;
        },
        logout : (state) => {
            state.isError = false;
            state.isLoading = false;
            state.isSuccess = false;
            state.user = null;
        }
    },

    extraReducers : (builder) =>{
        builder
        // .addCase(register.pending,(state )=>{
        //     state.isLoading = true;
        // }).addCase(register.fulfilled,(state, action )=>{
        //     state.isLoading = false;
        //     state.isSuccess = true;
        //     state.user = action.payload
        // }).addCase(register.rejected,(state, action )=>{
        //     state.isLoading = false;
        //     state.isSuccess = false;
        //     state.isError = true;
        //     state.user = null;
        //     state.message = action.payload;
        // })
        .addCase(login.pending,(state )=>{
            state.isLoading = true;
        }).addCase(login.fulfilled,(state, action )=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
        }).addCase(login.rejected,(state, action )=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.user = null;
        })
    }
})

export const {reset, logout} = authSlice.actions
export default authSlice.reducer
