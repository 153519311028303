// InitialSetup.tsx
import React from 'react'
import './InitialSetup.css'

const InitialSetup = () => {
  return (
    <div className='hcontainer'>
      <h2>One time Registration</h2>
      <div className='gcontent'>
        <h5>Zoom</h5>
        <ul>
          <li>An active free Zoom account is mandatory to join our Zoom class. This is for security reasons.</li>
          <li>Students must subscribe (if not already subscribed) for a free Zoom account at <a href='https://zoom.us/' target='_blank' rel='noopener noreferrer'>Zoom.us</a></li>
          <li>The Zoom class experience is best with the Zoom client app. Please download the Zoom client app from <a href='https://zoom.us/download#client_4meeting' target='_blank' rel='noopener noreferrer'>Zoom.us/download#client_4meeting</a></li>
        </ul>

        <h5>Chess.com</h5>
        <ul>
          <li>Our coaches play chess with students during the class. We also encourage students to play online with other players during their free time. Mostly, we use the chess platform Chess.com.</li>
          <li>If you don’t have one, register a free account at <a style={{ padding: '0px !important' }} href='https://www.chess.com/' target='_blank' rel='noopener noreferrer'>"www.Chess.com"</a></li>
          <li>Once registered, share your Chess.com username with the coach.</li>
          <li>Add our academy as a friend.</li>
          <li>Instructions:
            <ol>
              <li>Login to Chess.com with your username and password.</li>
              <li>Go to "Connect/Friends/Add Invite Friends".</li>
              <li>Search for "LearnChessAcademy" Username.</li>
              <li>Click on "Request".</li>
            </ol>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InitialSetup;