// CategoriesTable.tsx
import React from 'react';
import './NoOfRoundsTable.css'; // Import the CSS for styling

const NoOfRoundsTable: React.FC = () => {
	return (
		<div>
			<table className="NoOfRoundsTable">
				<thead>
					<tr>
						<th className="NoOfRoundsColumn">Category</th>
						<th className="NoOfRoundsColumn">Number of Rounds To Play</th>
						<th className="NoteColumn">Note</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td><b>Under 6</b><br /><br />2018 (or) later</td>
						<td>Only 2 </td>
						<td>After the 2nd round, players can go for lunch. <p></p>Must return by 3:45 PM for the prize distribution ceremony.</td>
					</tr>
					<tr>
						<td><b>Under 8</b><br /><br />2016 (or) 2017</td>
						<td>All 4</td>
					</tr>
					<tr>
						<td><b>Under 10</b><br /><br />2014 (or) 2015</td>
						<td>All 4</td>
					</tr>
					<tr>
						<td><b>Under 12</b><br /><br />2012 (or) 2013</td>
						<td>All 4</td>
					</tr>
					<tr>
						<td><b>13 & Above</b><br /><br />2011 (or) earlier</td>
						<td>3 Rounds</td>
						<td>In case of tiebreaks, those players will need to play a 4th round.</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default NoOfRoundsTable;
