import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createImage, getImageName, reset, updateActive } from './Features/chessSlice';
import { logout } from './Features/authSlice'
import './VideosForm.css'

const VideosForm: React.FC = () => {
  const [title, setTitle] = useState('');
  const [images, setImages] = useState<File | null>(); // Store the File object
  const [active, setActive] = useState('');
  const dispatch = useDispatch();

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(createImage({ title, images, active }) as any);
    setTitle('');
    setImages(null);
    setActive('');
  };

  const { user } = useSelector((state: any) => state.auth)
  const { imageName, isImage } = useSelector((state: any) => state.image)
  



  useEffect(() => {
    dispatch(getImageName() as any);
  }, [isImage])

  const handleLogOut = () => {
    dispatch(logout() as any)
  }

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setImages(selectedFile || null);
    }
  };

  const handleActive =(image : any, id : any) => {
    dispatch(updateActive({id, image}) as any)
    console.log('mvldw' , image, id);
  }

  

  return (
    <div className='container-admin'>
      <form onSubmit={onSubmit} >
        <div>
          <label htmlFor="title">Title</label>
          <input
            type="title"
            name="title"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <label htmlFor="images">Image</label>
          <input
            type="file"
            name="images"
            id="images"
            onChange={onFileChange}
          />
          <label htmlFor="active">Active</label>
          <input
            type="boolean"
            name="active"
            id="active"
            value={active}
            onChange={(e) => setActive(e.target.value)}
          />
        </div>
        <div>
          <button>Upload Video</button>

          <button onClick={handleLogOut}>
            logOut
          </button>
        </div>
      </form>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">title</th>
            <th scope="col">Name</th>
            <th scope="col">Active</th>
          </tr>
        </thead>
        <tbody>

          {
            imageName ? imageName.map((img: any) => (
              <>
                <tr>
                  <td>{img.title}</td>
                  <td>{img.imageName}</td>
                  <td><input type='checkbox' checked={img.active} onClick={(e) => handleActive(img.active, img._id)} className="checkbox"/></td>
                </tr>
              </>
            )) : ''

          }
        </tbody>
      </table>

    </div>
  );
};

export default VideosForm;
