import axios from 'axios';

const API_URL = 'http://localhost:5000/api/users'

// const register = async(userData) =>{
//     const res= await axios.post(API_URL, userData)

//     if(res.data){
//         localStorage.setItem('user', JSON.stringify(res.data))
//     }

//     return res.data

// }

const login = async (userData: any) => {
    console.log(userData)
    console.log("ji", await axios.post(API_URL,userData))
    const res = await axios.post(API_URL,userData)

    console.log(res.data);
    return res.data
}

const authService = {
    login
}

export default authService