// ScheduleTable.tsx
import React from 'react';
import './CategoriesTable.css'; // Reusing the same CSS file for consistent styling

const ScheduleTable: React.FC = () => {
	return (
		<div>
			<table className="scheduleTable">
				<thead>
					<tr>
						<th>Time</th>
						<th>Activity</th>
					</tr>
				</thead>
				<tbody>
				<tr className="tblCenterAlignedBlueBackground" >
						<td colSpan={2}>
							<b>Tournament</b>
						</td>
					</tr>

					<tr>
						<td>8:30 AM</td>
						<td>Report to the Venue</td>
					</tr>
					<tr>
						<td>8:40 AM</td>
						<td>Be seated as per the pairing</td>
					</tr>
					<tr>
						<td>8:50 AM</td>
						<td>Arbiter Briefing</td>
					</tr>
					<tr className="tblgrayBackground">
						<td>9:00 AM to 9:40 AM</td>
						<td>
							<b>Round 1</b>
						</td>
					</tr>
					<tr>
						<td>9:50 AM</td>
						<td>Be seated as per the pairing</td>
					</tr>
					<tr className="tblgrayBackground">
						<td>10:00 AM to 10:40 AM</td>
						<td>
							<b>Round 2</b>
						</td>
					</tr>
					<tr>
						<td>10:40 AM</td>
						<td>Light Snacks will be provided</td>
					</tr>
					<tr>
						<td>10:50 AM</td>
						<td>Be seated as per the pairing</td>
					</tr>
					<tr className="tblgrayBackground">
						<td>11:00 AM to 11:40 AM</td>
						<td>
							<b>Round 3</b>
						</td>
					</tr>
					<tr>
						<td>11:50 AM</td>
						<td>Be seated as per the pairing</td>
					</tr>
					<tr className="tblgrayBackground">
						<td>12:00 PM to 12:40 PM</td>
						<td>
							<b>Round 4</b> (If needed)
						</td>
					</tr>
					<tr className="tblcenterAligned">
						<td colSpan={2}>
							<b>Lunch Break</b> 
							<p>(Lunch is not provided)<br></br>
							Encourage younger kids to take a short nap, if feasible.</p>
						</td>
					</tr>
					<tr className="tblCenterAlignedBlueBackground" >
						<td colSpan={2}>
							<b>LCA Annual Meet & Prize Presentation Ceremony</b>
						</td>
					</tr>
					<tr>
						<td>3:45 PM</td>
						<td>Be seated for the ceremony</td>
					</tr>
					<tr className="tblgrayBackground">
						<td>4:00 PM to 5:30 PM</td>
						<td>
							<b>Fun games, Talent show, Special Awards,</b> <p> & <br></br><b>Tournament Prize Presentation</b></p>
						</td>
					</tr>
					<tr>
						<td>5:30 PM</td>
						<td>Group Photo</td>
					</tr>
					<tr className="tblgrayBackground">
					<td>5:45 PM</td>
						<td><b>Gift Hamper</b> Distribution to All Current LCA Students.
						<p>& <br></br>
						<b>Participation Certificate</b> Distribution to All Tournament Participants.</p>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default ScheduleTable;
