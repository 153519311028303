import axios from 'axios'

const API_URL = "http://localhost:5000/api/images/"


const getImageName = async () => {
    const response = await axios.get(API_URL);
    return response.data;
}

const getImage = async (image: any) => {

    const res = await axios.get(API_URL);
    
    const response = await axios.get(API_URL + image);

    console.log(response.data)

    return response.data;
}

const createImage = async (data: any) => {

    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('imageName', data.images);
    formData.append('active', data.active);

    console.log("image", data.images)
    console.log("formdata", formData);

    const response = await axios.post(API_URL, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        },
    });
    console.log(response.data)
    return response.data;
}

const deleteImage = async (id: any) => {
    const response = await axios.delete(API_URL + id);
    return response.data;
}

const updateActive = async (id: any, active : any) => {
    const response = await axios.get(API_URL + id);
    console.log(response.data)
    return response.data;
}


const chessService = {
    getImageName, getImage,
    createImage, deleteImage, updateActive
}

export default chessService