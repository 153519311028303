import React from 'react'
import './AttendClasses.css'

const AttendClasses: React.FC = () => {
  return (
    <>
      <div className='hcontainer'>
        <h2>Step-by-Step Guide for Online Class</h2>

        <h5>Before Class</h5>
        <div>
          <p>Step 1: Get Class schedule confirmation </p> 
          <p>Step 2: Make sure completed homework sent to coach (if any)</p>
        </div>

        <h5>Class</h5>
        <div>
          <p>Step 1: Join the class </p> 
          <p>Step 2: Disruption during class</p>
           </div>

        <h5>After Class</h5>
        <div>
          <p>Step 1: Finish the homework </p>
          <p>Step 2: Send the completed homework scanned pages (if any)</p>
        </div>



      </div>
    </>
  )
}

export default AttendClasses