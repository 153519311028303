import React from 'react'
import './Accomplishments.css'

import image1 from '../images/PHOTO-2022-04-20-15-36-41 19 1 (1).png'
import image2 from '../images/PHOTO-2022-04-20-15-36-41 16 1.png'
import image3 from  '../images/PHOTO-2022-04-20-15-36-41 18 1.png'
import image4 from '../images/PHOTO-2022-04-20-15-36-41 22 1.png'
import image5 from '../images/IMG_4014 1.png'

const Accomplishments: React.FC = () => {
    return (
        <>
            <div className='Acontainer1'>

                <div className='content'>

                    <h1>Our Success speaks about our </h1> <h1>commitment</h1>

                    <hr></hr>

                    <p>The lead coach, Murali Krishna, and his team of professional chess coaches have trained thousands of chess players. </p> <p>Many students have rapidly transformed from beginners to expert chess players. </p> <p>A high percentage of our students come through referrals.This is a result of our groundbreaking methodology, systematic approach, and commitment.</p>

                </div>

                <div className='img1'>
                <img src={image1} alt="Few of our academy's award winners"></img>
                </div>

                <div className='img2'>
                    <img src={image2} alt="our academy's award winners"></img>
                </div>

            </div>

            <div className='Acontainer2'>
                <div className='card'>

                    <h3>Our students</h3>
                    <ul>
                        <li>Different Stages (Beginners, Intermediate, Advanced & Tournament players)
                        </li>
                        <li>
                            Different Ages (4 years - 60 years)
                        </li>
                        <li>
                            Different Cultural backgrounds
                        </li>
                        <li>Different walks of life (Students, CEX, Employees, Businessmen, House wives)</li>
                    </ul>

                </div>

                <div className='img1'>
                    <img src={image3} alt="Few of our academy's award winners"></img>                    
                </div>

                <div className='img2'>
                    <img src={image4} alt="Few of our academy's award winners"></img>
                </div>

            </div>

            <div className='Acontainer3'>
                <div className='card'>

                    <h3>Our students</h3>
                    <ul>
                       <li>Our tournament-oriented training has yielded excellent outcomes.</li>
                       <li>We also trained a few special needs children and got good results.</li>
                    </ul>

                </div>

                <div>
                    <img src={image5} alt="Few of our academy's award winners"></img>                    
                </div>

            </div>

        </>
    )
}

export default Accomplishments