import React from 'react'
import './Contact.css'
import image1 from '../images/email.png'
import image2 from '../images/whatsapp.png'
import image3 from '../images/message.png'

const FreeDemo: React.FC = () => {
  return (

    <>
      <div className='containerC'>

        <h5> Contact us</h5>
        <div className='contentC'>
          <p>
            Hi Coach,
          </p>
          <p>
            Please provide more free demo classes.
          </p>
        </div>
        <div className='navButton'>



          

          <a href="mailto:murali@lca.sg?body=Hi Coach, Please provide more details about your classes."> <button> <img src={image1} alt="eMail icon"></img>e-Mail</button></a>


          <a href="https://wa.me/+6597224377/?text=Hi Coach,%OaPlease provide more details about your classes."><button> <img src={image2} alt="Whatsapp icon"></img>Whatsapp</button></a>

          <a href="sms:/+6597224377/&amp;body=Hi Coach, Please provide more details about your classes."><button> <img src={image3} alt="SMS icon"></img>SMS</button></a>

          


        </div>
      </div>
    </>
  )
}

export default FreeDemo