import React from 'react'
import { Space, Layout } from 'antd';
import google from '../images/Google.png';
import phone from '../images/phone.png';
import Logo from './../images/logo.png';

const { Footer } = Layout

const Footers: React.FC = () => {

  return (
<>

      <div className="foot" style={{ backgroundColor: 'black' }}>
        <div className='row-f'>
          <div className="row-f-1">
            <a >
              <img className="img-thumbnail mb-3" src={Logo} alt="Sticky footer screenshot" width="96" height="60" />
              <div className='row-f-i'>

              <a href="tel:+65 9722 4377"><img className="img-thumbnail mb-3 img" src={phone} alt="Sticky footer screenshot" width="25" height="30" /></a>


               <a href="mailto:murali@lca.sg">
                <img className="img-thumbnail mb-3 img" src={google} alt="Sticky footer screenshot" width="25" height="30" /> </a>
                
              </div>
            </a>

          </div>
          <footer >
            <ul className="nav justify-content-center border-top nav-foot">
              <li className="nav-item"><a href="/" className="nav-link px-2 text-muted">Home</a></li>
              <li className="nav-item"><a href="/methodology" className="nav-link px-2 text-muted">Methodology</a></li>
              <li className="nav-item"><a href="/approach" className="nav-link px-2 text-muted">3Ps Approach</a></li>
              <li className="nav-item"><a href="/accomplishments" className="nav-link px-2 text-muted">Accomplishments</a></li>
              <li className="nav-item"><a href="/aboutUs" className="nav-link px-2 text-muted">About us</a></li>
              <li className="nav-item"><a href="/resources" className="nav-link px-2 text-muted">Resources</a></li>
            </ul>
          </footer>
        </div>
        <hr style={{ border: '1px solid #FFA319', width: '100%' }}></hr>
        <p style={{ textAlign: 'center', width: '100%' }}>
          <span style={{ color: "#FFFFFF" }}>
            Regd No. 53143478L</span>   <span style={{ color: "#FFA319" }}>©2023  Learn Chess Academy</span>
        </p>

      </div>    </>

  );
}

export default Footers