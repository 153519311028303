import React from 'react';
import './Home.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faCircleXmark,
	faCircleCheck,
	faFileCircleCheck,
	faPlugCircleXmark
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Card, Col, Row, Space, Carousel } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import image1 from '../images/3d-hd-chess-game-n8g5nghsevozvc6s 1.png';
import image2 from '../images/Untitled design (14) 2 (1).png';
import image3 from '../images/Untitled design (14) 3 (1).png';
import image4 from '../images/Character.png';
import image5 from '../images/Wrong (1).png';
import image6 from '../images/t (7) 2 (2).png';
import image7 from '../images/Group (3).png';
import image8 from '../images/Best Badge (1).png';
import image9 from '../images/Best Seller (1).png';
import image10 from '../images/money management (1).png';
import image11 from '../images/Group (4).png';

import Image from '../images/Image .jpg';
// import Testimonials from './Testimonials';

import Group1 from '../images/Group 23.png';
import class1 from '../images/Boy.png';
import class2 from '../images/Group 35 (1).png';
import class3 from '../images/Online Class.png';
import class4 from '../images/chess website.png';
import correct from '../images/Correct.png';

const contentStyle: React.CSSProperties = {
	height: '300px',
	color: '#fff',
	lineHeight: '160px',
	textAlign: 'center'
};

const style: React.CSSProperties = { background: 'rgba(0,0,255,0.02)' };
const { Meta } = Card;
const Home: React.FC = () => {
	const navigate = useNavigate();

	const handleDemo = () => {
		navigate('/demo');
	};

	const handleContact = () => {
		navigate('/contactUs');
	};

	const handleNavigate = (a: any) => [navigate('/' + a)];

	return (
		<>
			<div className="containerh">
				<div className="row">
					<div className="col content-top">
						<div className="slider">
							<div className="home-n-p tournament">
								<h1> LCA Friendly Tournament - 2024</h1> All LCA students are invited to participate{' '}
								<br />
								<span className="home-n-l">
									<Link to="/tournament"> Click Here</Link>
								</span>{' '}
								for more details{' '}
							</div>
							<div className="caption">
								<h1>LEARN CHESS ACADEMY</h1>
								<hr style={{ border: '1px solid #FFFFFF', width: '95%' }}></hr>
							</div>
							<p className="p">
								A popular Singapore based Institute for{' '}
								<span style={{ fontStyle: 'italic', color: 'red', fontWeight: '500' }}>Hybrid</span>{' '}
								Chess coaching
							</p>

							<button type="button" className="btn-demo" onClick={handleDemo}>
								Free Demo class
							</button>
							<button type="button" className="btn-ctn" onClick={handleContact}>
								Contact us
							</button>

							<p className="home-p">
								Looking for Online classes out of Singapore?{' '}
								<span className="home-pl">
									<a href="https://onlinelca.com/">Click Here </a>
								</span>
							</p>
							<div className="home-n-p annual">
								<h1> LCA Annual Fun Meet - 2024 </h1> Learn Chess Academy invites all LCA students
								to attend the Meet
								<span className="home-n-l">
									{' '}
									<br />
									<Link to="/annual">Click Here</Link>{' '}
								</span>
								for more details
							</div>
						</div>
					</div>
					<div className="col content-top-r">
						<img className="homeImage-top" src={image1} alt="Chess Board Graphic"></img>
					</div>
				</div>
			</div>

			<div className="hcontainer2">
				<h2>Revolutionary Chess Coaching Methodology</h2>

				<div className="head">
					<p>Master chess quickly with minimal effort</p>
					<hr></hr>
				</div>

				<div className="content">
					<div className="hcard1">
						<div className="step1">
							<p>Grasp Core </p> <p> Fundamentals</p>
						</div>
					</div>
					<div className="hcard2">
						<div className="step2">
							<p>Learn Mnemonic- </p> <p>based Techniques</p>
						</div>
					</div>
					<div className="hcard3">
						<div className="step3">
							<p>Strive for </p> <p> Accuracy</p>
						</div>
					</div>
					<div className="hcard4">
						<div className="step4">
							<p>Harness Steps</p> <p>Method & AI support</p>
						</div>
					</div>

					<div className="hcard5">
						<div className="step5">
							<p>Explore Fun </p> Chess Variants<p></p>
						</div>
					</div>

					<div className="htable">
						<table>
							<tbody>
								<tr>
									<td>
										{' '}
										<div className="ht1content">
											<img src={image5} alt="Box Graphic"></img>
											<p>Memorize Chess Openings</p>
										</div>
									</td>
									<td>
										{' '}
										<div className="htcontent">
											<img src={image5} alt="Box Graphic"></img>
											<p>Studying overwhelming Chess materials</p>
										</div>
									</td>
									<td>
										{' '}
										<div className="htcontent">
											<img src={image5} alt="Box Graphic"></img>
											<p>Craving fast - paced Chess</p>
										</div>
									</td>
									<td>
										{' '}
										<div className="htcontent">
											<img src={image5} alt="Box Graphic"></img>
											<p>Working with mixed level resources</p>
										</div>
									</td>
									<td>
										{' '}
										<div className="htcontent">
											<img src={image5} alt="Box Graphic"></img>
											<p>Limiting play to Standard Chess</p>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div className="img1">
					<img src={image2} alt="Chess Board Graphic"></img>
				</div>

				<div className="img2">
					<img src={image3} alt="Chess Board Graphic"></img>
				</div>
			</div>

			<div className="hcontainer3">
				<h2>3Ps Learning Approach</h2>

				<div className="hcard1">
					<h4>PHILOSOPHY</h4>

					<p>Fundamentals mastery</p>
				</div>

				<div className="hcard2">
					<h4>PSYCHOLOGY</h4>

					<p>Conducive mental state is vital for grasping</p>
				</div>

				<div className="hcard3">
					<h4>PHYSIOLOGY</h4>

					<p>Physical stability is crucial for effective & efficient learning experience. </p>
				</div>
			</div>

			<div className="container2">
				<div>
					<h2>Expert Coaching from Renowned Professionals</h2>
				</div>
				<div className="container2-content">
					<img src={Group1} alt="group photo" />

					<div className="container2-cards">
						<p>
							Lead Coach: <span className="bold"> Murali Krishna Chitrada </span>{' '}
						</p>{' '}
						<p>
							{' '}
							<span className="bold">National</span> Chess Player{' '}
						</p>{' '}
						<p>Professional Chess Coach in Singapore </p>{' '}
						<p>
							Over <span className="bold">15 years</span> of experience{' '}
						</p>{' '}
						<p>Invented Revolutionary Coaching Methodology </p> <p>Innovated the 3P's Approach </p>{' '}
						<p>Invited to speak at various platforms</p>
					</div>
				</div>

				<div className="b-content">
					<p>
						Accomplishments of our <span className="bold">team</span> of coaches.....
					</p>
				</div>
			</div>

			<div className="container5">
				<h2>Why ‘Learn Chess Academy’ ?</h2>
				<div className="container5-row-1">
					<div className="but-5">
						<button onClick={(e) => handleNavigate('accomplishments')}>
							{' '}
							<img src={image6} alt="Brain bulb Graphic"></img>{' '}
							<p>Coached by Team of Successful Professional</p>
						</button>
						<button onClick={(e) => handleNavigate('methodology')}>
							<img src={image7} alt="Chess Knight Graphic"></img>{' '}
							<p>Breakthrough Chess coaching methodology</p>
						</button>
						<button onClick={(e) => handleNavigate('approach')}>
							<img src={image8} alt="Gold Medal Graphic"></img>
							<p>3Ps Systematic learning approach</p>
						</button>
						<button onClick={(e) => handleNavigate('accomplishments')}>
							<img src={image9} alt="Thumbs up Graphic"></img>
							<p>Proven Track Record</p>
						</button>
					</div>
				</div>

				<div className="container5-row-1-1">
					<div className="but-5">
						<button onClick={(e) => handleNavigate('terms')}>
							{' '}
							<img src={image9} alt="Thumbs up Graphic"></img> <p>No obligations</p>
						</button>
						<button onClick={(e) => handleNavigate('terms')}>
							<img src={image10} alt="Coin in hand Graphic"></img>
							<p>Affordable</p>
						</button>
					</div>
					<div className="container5-row-1-2">
						<button onClick={(e) => handleNavigate('cognition')}>
							{' '}
							<img src={image11} alt="Rubiks cube Graphic"></img>
							<div>
								<p>No obligations or conditions</p>
								<p>Affordable with great value for money</p>
							</div>
						</button>
					</div>
				</div>
			</div>

			<div className="container6">
				<h2>How the Classes are Organized?</h2>

				<div className="card-6">
					<div className="card-6-content-1">
						<h5>CLASS MODE & FREQUENCY </h5>
					</div>

					<div className="card-6-content-2">
						<h1>Weekly Online </h1>
						<h1>Classes</h1>
					</div>

					<div className="card-6-content-3">
						<p>* In-person offline classes available in Singapore</p>
					</div>
				</div>

				<div className="card-6-row">
					<div className="card-6">
						<div className="card-6-content-1">
							<h5>CLASS TYPES</h5>
						</div>

						<div className="card-6-content-2-col">
							<div className="card-6-content-2-col-1">
								<img style={{ width: '50px' }} src={class1} alt="One Student Graphic"></img>

								<p>One-to-One Individual Class (More Effective)</p>
							</div>
							<div className="card-6-content-2-col-1">
								<img src={class2} alt="2 students Graphic"></img>

								<p>Two-Student Combined Class (More Economical)*</p>
							</div>
						</div>
						<div className="card-6-content-3">
							<p>* 2 students can be from the same family (or) known circle.</p>
						</div>
					</div>

					<div className="card-6">
						<div className="card-6-content-1">
							<h5>PLATFORM & DEVICES </h5>
						</div>

						<div className="card-6-content-2-col">
							<div className="card-6-content-2-col-2">
								<img src={class3} alt="Computer Monitor Graphic"></img>

								<p>Zoom for classes</p>
							</div>
							<div className="card-6-content-2-col-2">
								<img src={class4} alt="Chess knight in monitor Graphic"></img>

								<p>Chess.com to play</p>
							</div>
						</div>

						<div className="card-6-content-3">
							<p>* Can Register for Free. No paid subscription is needed</p>
						</div>
					</div>
				</div>
			</div>

			{/* <Testimonials /> */}
		</>
	);
};

export default Home;
