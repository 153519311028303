import React from 'react'
import './Methodology.css'

import image1 from '../images/Company_Goup__6_-removebg 1.png'
import image2 from '../images/Company Goup (8) 1.png'
import image3 from '../images/Correct (1).png'
import image4 from '../images/Company_Goup__5_-removebg-preview 1.png'
import image5 from '../images/Company Goup (1) 1 (1).png'
import image6 from '../images/FUN.png'
import image7 from '../images/Company Goup (11) 1.png'
import image8 from '../images/Company Goup (12) 1.png'
import image9 from '../images/Vector (3).png'
import image10 from '../images/Vector (5).png'

const Methodology: React.FC = () => {
  return (
    <>
      <div className='container1'>

        <div>
          <img src={image1}  alt="Chess board Grapic"></img>
        </div>

        <div className='btn1'>
          <p>Innovative</p>
          <p>Coaching </p>
          <p>Methodology</p>
        </div>

        <div className='btn2'>
          <p>Explore</p>
          <p>Chess</p>
          <p>Variants</p>
        </div>

        <div className='btn3'>
          <p>Harness</p>
          <p>Proven</p>
          <p>Materials</p>
        </div>

        <div className='btn4'>
          <p>Strive for</p>
          <p>accuracy</p>
        </div>

        <div className='btn5'>
          <p>Learn</p>
          <p>Mnemonic</p>
          <p>Techniques</p>
        </div>

        <div className='btn6'>
          <p>Grasp core</p>
          <p>principles</p>
        </div>

      </div>

      <div className='mcontainer2'>
        <div className='mcontent'>
          <h2 style={{ paddingLeft: "18%" }}>Move Beyond Memorization:</h2>
          <h2 style={{ paddingLeft: "48%" }}>Absorb Chess Openings' Concepts</h2>
        </div>

        <div className='mcontent1'>
          <div className="card1">
            <div>
              <h3 style={{ paddingBottom: "1%" }}>Understand Fundamentals</h3>
            </div>
            <div >
              <p >
                <img width="20" height="20" src={image3}  alt="Chess Board Graphic" style={{ marginRight: '1vw' }}></img>


                <span >
                  Grasp Opening Base Principles.
                </span>
              </p>
            </div>
            <div >
              <p >
                <img width="20" height="20" src={image3}   alt="Chess Board Graphic" style={{ marginRight: '1vw' }}></img>
                <span >
                  Follow best Practices.
                </span>
              </p>
            </div>
            <div >
              <p >
                <img width="20" height="20" src={image3}   alt="Chess Board Graphic" style={{ marginRight: '1vw' }}></img>
                <span >
                  Begin Planning & Strategizing Early.
                </span>
              </p>
            </div>
            <div >
              <p >
                <img width="20" height="20" src={image3}   alt="Chess Board Graphic"  style={{ marginRight: '1vw' }}></img>

                <span >
                  Employ Analogy & Logic from the Start.
                </span>
              </p>
            </div>
            <div >
              <p >
                <img width="20" height="20" src={image3}   alt="Chess Board Graphic" style={{ marginRight: '1vw' }}></img>
                <span >
                  Practice with 960 Chess Variant.
                </span>
              </p>
            </div>
            <div >
              <p >
                <img width="20" height="20" src={image3}   alt="Chess Board Graphic"  style={{ marginRight: '1vw' }}></img>
                <span >
                  Evade Opening Vulnerabilities.
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className='mcontent2'>
          <div className='card2'>
            <div>
              <div>
                <h3 style={{ paddingBottom: "1%" }}>No By-hearting Chess Openings</h3>
              </div>
              <div>
                <ul>
                  <li>
                    There are over 1000 named Chess Openings and Variants.
                  </li>
                  <li>
                    Memorizing openings is time-consuming
                  </li>
                  <li>
                    It's Difficult, and confusing to remember all variations and sub-variations.
                  </li>
                  <li>
                    It limits your ability to think creatively and helps you only in the first few moves.
                  </li>
                  <li>
                    It's not useful if your opponent deviates from the expected line
                  </li>
                  <li>
                    It's not necessary for all players.
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>

        <div className='mcontentl'>
        <img src={image9}   alt="Chess Board Graphic"  ></img>          
          <p> Playing from memory takes away the fun of playing Chess</p>
        </div>

      </div>


      <div className='mcontainer3'>
        <div className='mcontent1'>
          <h2 style={{ paddingLeft: "18%" }}>Bypass Extensive Literature:</h2>
          <h2 style={{ paddingLeft: "48%" }}>Learn few Mnemonic-based Techniques</h2>
        </div>

        <div>
          <img src={image4}   alt="Chess Board Graphic" ></img>
        </div>

        <div className='mcontent-p1'>
          <p>
            There's no need to spend hours going through Chess books, articles, software, and videos to achieve a good standard.
          </p>

        </div>

        <div className='mcontent-p2'>
          <p >
            We've simplified the most important Chess techniques into a handful of easy-to-remember mnemonics.
          </p>

        </div>



        <div className='mcontentl'>
                  
          <p> <img src={image9}   alt="Chess Board Graphic"  ></img>Simplicity is the key to brilliance.</p>
        </div>


      </div>

      <div className='mcontainer4'>

        <div className='mcontent'>
          <h2 style={{ paddingLeft: "18%" }}>Resist Impulsive Moves:</h2>
          <h2 style={{ paddingLeft: "48%" }}>Play with Precision</h2>
          <p style={{ paddingLeft: "18%" }}>
            Focusing solely on speed may compromise accuracy. Enhance speed by training your mind for accuracy and increasing practice time.
          </p>
        </div>

        <div className='mcontent'>
          <img src={image5}   alt="Chess Board Graphic" ></img>
        </div>

        <div className='mcontentl'>
        <img src={image10}   alt="Chess Board Graphic" ></img>          
        <p>In chess, accuracy is always trumped by timing.</p>
        </div>

      </div>

      <div className='mcontainer5'>

        <div className='mcontent'>
          <h2 style={{ paddingLeft: "15%" }}>Avoid Inconsistent Material Standards:</h2>
          <h2 style={{ paddingLeft: "48%" }}>Embrace Proven, Structured Resources</h2>
          <p style={{ textAlign: 'center', fontSize: '110%', padding: '3%', color: 'white' }}>
            Varying standards practice materials available in different modes, such as books, software, and apps.
          </p>

          <p style={{ textAlign: 'center', fontSize: '110%', paddingLeft: '30%', color: 'white', width: '75%', fontWeight: '500' }}>
            However, for a quick and systematic acquisition of chess skills, we recommend utilizing the successful and structured "Steps Method" materials and AI-based resources.
          </p>

        </div>

        <div className='mcontentl'>
       
        <p><img src={image10}   alt="Chess Board Graphic" ></img>In chess, accuracy is always trumped by timing.</p>
        </div>



      </div>

      <div className='mcontainer6'>
        <div className='mcontent'>

          <h2 style={{ paddingLeft: "15%" }}>Expand Beyond Standard Chess:</h2>
          <h2 style={{ paddingLeft: "48%" }}>  Enhance Skills with <img src={image6}   alt="Fun image"  width='8%'></img>  Chess Variants</h2>

        </div>

        <div className='card'>

          <div className="card1">
            <p>
              Chess Planning and Analytical skills can be trained using various methods.
            </p>

            <p>
              One enjoyable way to do this is by training with different Chess variants, such as:
            </p>

          </div>

          <div className='card2'>
            <div className='card-1'>
              <ul>
                <li>Two-move Chess</li>
                <li>Anti Chess</li>
                <li>Three click</li>
              </ul>

            </div>

            <div className='card-2'>
              <ul>
                <li>Racing Kings</li>
                <li>King of Hill</li>
                <li>Horde</li>
              </ul>
            </div>
          </div>

        </div>

        <div>
          <div className='imgp-1'>
            <img src={image7}   alt="Chess Board Graphic" ></img>
          </div>

          <div className='imgp-2'>
            <img src={image8}   alt="Chess Pieces Graphic" ></img>
          </div>

        </div>

        <div className='mcontentl'>    
        <p>Chess is a game of imagination and strategy, and playing different variants can help you to develop new ways of thinking and problem-solving</p>
        </div>

      </div>

    </>

  )
}

export default Methodology