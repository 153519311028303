import './Hybrid.css';
import ComparisionTable from './ComparisionTable';
import { useNavigate } from 'react-router-dom';
const Hybrid: React.FC = () => {
	const navigate = useNavigate();
	const onClickHandler = () => {
		navigate('/demo');
	};
	return (
		<div>
			<div className="container-tournament">
				<h1>
					<u>Learn Chess Academy (LCA)</u>
				</h1>
				<div className="hybrid-container ">
					<div className="content-hybrid">
						<p className="home-p-l">A popular Singapore-based Institute for Chess coaching</p>
						<p style={{ color: 'red' }}></p>
					</div>

					<hr
						style={{
							height: '5px',
							backgroundColor: 'black',
							marginTop: '2px',
							marginBottom: '2px'
						}}
					/>

					<div className="content-tournament">
						<h2>Hybrid Online Chess Classes:</h2>
						<br />
						<p className="home-p-l">
							<b>
								Hybrid coaching combines online classes on regular days with in-person classes on
								holidays (upon request).
							</b>
						</p>

						<div>
							<p>
								There are pros and cons of both the online and in-person modes. Online classes are
								ideal for explaining certain concepts, while in-person classes are better for
								teaching certain practices. Online classes are economical and easy to schedule,
								while in-person classes provide a personal touch.
							</p>
							<button type="button" className="btn-demo-hybrid" onClick={onClickHandler}>
								Free Demo class
							</button>
						</div>
						<p>
							<b>Hybrid coaching provides the advantages of both in-person and online classes.</b>
						</p>
						<p>
							<b>Comparision:</b>
							<ComparisionTable />
						</p>
					</div>

					<br></br>
				</div>
			</div>
		</div>
	);
};

export default Hybrid;
