
import React, { useEffect,useState } from 'react'
import {reset, login} from '../Features/authSlice'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
const Login : React.FC = () => {

    const [formData, setFormData] = useState({
        email: '',
        password: '',
      })

      const navigate = useNavigate()
      const dispatch = useDispatch()
      
    
      const {user, isError, isSuccess, isLoading, message} = useSelector((state : any)=>state.auth)
    
      useEffect(()=>{
        if(isError){
            console.log(isError)
        }
        if(isSuccess && user.name){
          navigate('/admin');
        }
        dispatch(reset())
      },[user, isError, isSuccess, message, navigate, dispatch])
    
      const {email, password} = formData
    
      const onChange = (e :any) =>{
    
        setFormData((prevState)=>({
          ...prevState,
          [e.target.name] : e.target.value
        }))
      }
    
      const onSubmit = (e : any) =>{
        e.preventDefault();
        const userData = {
          email, password
        }
        dispatch(login(userData) as any)
      }
      
  return (
    <div style={{padding : '75px'}}>
      <section>
        <p>Please Login to upload videos</p>
      </section>
      <section>
        <form onSubmit={onSubmit}>
          <div >
            <input type= 'email' name='email' placeholder='Please enter the email' id='email' value={email}className='form-control' onChange={onChange}/>
          </div>
          <div >
            <section>
              <input type='password' name='password' value={password} placeholder='Please enter the password' className='form-control' onChange={onChange}/>
            </section>
          </div>
          <div >
            <button type='submit' >submit</button>
          </div>
        </form>
      </section>
    </div>

  )
}

export default Login