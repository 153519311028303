import React from 'react'
import './Approach.css'
import image1 from '../images/Group 83.png'
import image2 from '../images/Group 24.png'
import image3 from '../images/Group 29.png'
import image4 from '../images/Group 30.png'

const Approach: React.FC = () => {
  return (
    <>
      <div className='acontainer1'>

        <div className='acontent'>

          <h1>Maximize Your Potential with the </h1> <h1>3P’s Approach:  </h1> <h1>
            Body, Mind & Strategy</h1>

          <hr></hr>

          <p>
            Are you ready to take your game to the next level? </p>
          <p> Follow Our Ground breaking 3P’s Coaching Approach.
          </p>

        </div>

        <div>
          <img src={image1} alt="Body Mind Strategy graphic"></img>
        </div>

      </div>

      <div className='acontainer2'>
        <div className='card'>

          <h3>Physiology</h3>

          <p>First, we ensure that the student's body is in the right state to learn by having them sit in an upright, spine-aligned posture. </p>
          <p>Simple yoga postures, such as Lotus Pose, Diamond Pose, and Surya Namaskar, help develop stamina. If needed or requested, we guide students in practicing these postures.</p>

        </div>

        <div>
          <img src={image2} alt="My studetns masked"></img>
        </div>

      </div>

      <div className='acontainer3'>
        <div className='card'>

          <h3>Psychology</h3>
          <p>Next, we help the student enter the right psychological state by cultivating mindfulness and focus, ensuring their full engagement in the coaching process.</p>
          <p>If needed or requested, we guide basic meditation, which helps improve concentration.</p>

        </div>

        <div>
          <img src={image3} alt="My studetns masked"></img>
        </div>

      </div>

      <div className='acontainer4'>
        <div className='card'>

          <h3>Philosophy</h3>

          <p>Finally, when the body and mind are in a receptive state, we teach chess strategies and philosophy.</p>

        </div>

        <div>
          <img src={image4} alt="My studetns masked"></img>
        </div>

      </div>




    </>


  )
}

export default Approach