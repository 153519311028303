import axios from 'axios'

const API_URL = "http://localhost:5000/api/videos/"


const getVideoName = async () => {
    const response = await axios.get(API_URL);
    return response.data;
}

const getVideo = async (image: any) => {
    
    const response = await axios.get(API_URL + image);

    return response.data;
}

const createVideo = async (data: any) => {

    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('image', data.video);
    formData.append('active', data.active);
    console.log("image", data.image)
    console.log("formdata", formData);

    const response = await axios.post(API_URL, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        },
    });
    console.log(response.data)
    return response.data;
}

const deleteVideo = async (id: any) => {
    const response = await axios.delete(API_URL + id,);
    return response.data;
}



const chessVService = {
    getVideoName, getVideo,
    createVideo, deleteVideo
}

export default chessVService