import React from 'react';
import './ComparisionTable.css';
const ComparisionTable: React.FC = () => {
	return (
		<div>
			<table className="comparisionTable">
				<thead>
					<tr>
						<th className="categoryColumn">Benefits</th>
						<th className="yearColumn">Hybrid</th>
						<th className="yearColumn">Online</th>
						<th className="yearColumn">In-person</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Economical</td>
						<td>More</td>
						<td>More</td>
						<td>Less</td>
					</tr>
					<tr>
						<td>Learning Physical Object Puzzles (Example: Rubiks' cubes, Pop-it)</td>
						<td>Easy</td>
						<td>Not Easy</td>
						<td>Easy</td>
					</tr>
					<tr>
						<td>Learning Digital Puzzles (Example: Sudoku, Slider)</td>
						<td>Easy</td>
						<td>Easy</td>
						<td>Not Easy</td>
					</tr>
					<tr>
						<td>Class (Re)scheduling</td>
						<td>Flexible</td>
						<td>Flexible</td>
						<td>Rigid</td>
					</tr>
					<tr>
						<td>Personal touch & Hands-on coaching experience</td>
						<td>Good</td>
						<td>Normal</td>
						<td>Better</td>
					</tr>
					<tr>
						<td>Protection from Contagious diseages</td>
						<td>More</td>
						<td>More</td>
						<td>Less</td>
					</tr>
					<tr>
						<td>Screen Time</td>
						<td>More</td>
						<td>More</td>
						<td>Less</td>
					</tr>
					<tr>
						<td>Geographical limitations</td>
						<td>Sometimes</td>
						<td>No</td>
						<td>Yes</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default ComparisionTable;
