import React from 'react';
import './Tournament.css';
import CategoriesTable from './CategoriesTable';
import NoOfRoundsTable from './NoOfRoundsTable';
import ScheduleTable from './ScheduleTable';
import Building from './../assets/building.png';
import Parking from './../assets/parking.png';
import Hall from './../assets/hall.png';
import Entrance from './../assets/entrance.png';
import Podium from './../assets/podium.png';
const Tournament: React.FC = () => {
	return (
		<div>
			<div className="container-tournament">
				<h1>
					<u>LCA Friendly Tournament - 2024</u>
				</h1>
				<div className="tournament-container">

					{/* Tournament Enrollment link taken out after the registration period.	
					<div className="content-tournament">
						<h3>
							Tournament Enrollment: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<span style={{ fontSize: '20px' }}>
								Click this Link to Register 👉 &nbsp;&nbsp;
								<span className="home-n-l">
									<a href=" https://forms.gle/hpn3b6zsn7cEC3XA8" target="_blank">
										{' '}
										https://forms.gle/hpn3b6zsn7cEC3XA8
									</a>
								</span>
							</span>
						</h3>



						<p style={{ color: 'red' }}>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							* *{' '}
							<b>
								Registration Closing Date <span>15/04/2024</span> (or) Upon reaching first 30
								entries. Please hurry up...
							</b>{' '}
							* *
						</p>
					</div>

<br></br>
					<br></br>

					<hr
						style={{
							height: '5px',
							backgroundColor: 'black',
							marginTop: '2px',
							marginBottom: '2px'
						}}
					/>
					*/}


{/* Added after closing registrations */}
					<div className="content-tournament ">
						<h2>How to Practice Until Tournament Day?</h2>
						<br></br>
						<p className="home-p-l">
							👉 &nbsp; <b>Daily Practice:</b> Until April 30, 2024, play one serious 30-minute live game on Chess.com each day against unknown players.
						</p>
						<p>
							👉 &nbsp; <b>Think & Play:</b> Take your time to think through each move carefully.
						</p>
						<p>
							👉 &nbsp; <b>Safety First:</b> Ensure each move is safe.
						</p>
					</div>

					<br></br>

					<hr
						style={{
							height: '5px',
							backgroundColor: 'black',
							marginTop: '2px',
							marginBottom: '2px'
						}}
					/>

					<div className="content-tournament">
						<h2>Preparation for the Day Before the Tournament (April 30, 2024):</h2>
						<br></br>
						<p className="home-p-l reduce-gap">
							<b>Pack Your Items:</b> Check that all pieces are present in your chess set. Pack your chess board, pieces, Rubik's Cubes and chess clock (if you own one) in a bag.
						</p>
						<p>
							Note: Notation papers and pencils will be provided at the tournament venue. Participants do not need to bring their own.
						</p>

					<br></br>
						<p>
							<b> Sleep early:</b> Aim for a good night’s sleep to ensure peak performance during the tournament.
						</p>
					</div>

					<br></br>

					<hr
						style={{
							height: '5px',
							backgroundColor: 'black',
							marginTop: '2px',
							marginBottom: '2px'
						}}
					/>

					<div className="content-tournament">
						<h2>Tournament Day Preparation (May 1, 2024):</h2>
						<br></br>
						<p className="home-p-l reduce-gap">
							<b>Items to Bring: </b> Bring your own <b>Chess Set</b> & Chess clock(if you have one) to the tournament venue.
						</p>
						<p>
							Note: It is the responsibility of each participant to manage their chess set. Ensure all pieces are accounted for and collected before leaving the table. Do not leave them in the tournament hall.
						</p>
						<p>
							Bring your own Rubik’s Cube <b>only</b> if you are demonstrating during the talent show.
						</p>
						<br></br>
						<p className="home-p-l reduce-gap">
							<b>Reach early:</b> Arrive early and be seated at your assigned spot according to the pairing, at least <b>10 minutes before</b> the round begins.
						</p>
						<p>
							For details, see the <a href="#ZeroStart">Zero Start Rule</a> section.
						</p>

					</div>

					<br></br>

						<b>Venue:</b>
							<br></br>
							Spring Auditorium
							<br></br>2 Bukit Merah Central, Room Number P-301, Singapore 159835
							<br></br>
							<a href="https://maps.app.goo.gl/BuVhXB6WZyysW6g47" target="_blank">
								{' '}
								https://maps.app.goo.gl/BuVhXB6WZyysW6g47
							</a>
							<br></br>
							<br></br>

							<p>
							👉 &nbsp; For detailed instructions on how to get there, see the <a href="#howToGetThere">How to get there</a> section.
							</p>
						<br></br>
	
					<p>
							<b>Pairing Sysem:</b> Swiss system (Each player must play multiple rounds)
						</p>

					<p>
						<b>Number Of Rounds to Play :</b> Depends on the category as per below table
						<p></p>
						<div className="App">
							<header className="App-header">
								{/* Other components or content */}
								<NoOfRoundsTable />
							</header>
						</div>
						</p>


						<br></br>
						<br></br>
						<b>Tournament Schedule:</b>
						<div className="App">
							<header className="App-header">
								{/* Other components or content */}
								<ScheduleTable />
							</header>
						</div>
						<br></br>

						<br></br>

						<p>
							<b>ENTRY TO THE TOURNAMENT HALL:</b> <br></br>
							Only players are allowed into the Tournament hall. Parents & visitors are not allowed.
							<br></br>
							(This is to reduce noise and distractions to the players. Seeking your understanding)
						</p>

					<br></br>
					<hr
						style={{
							height: '5px',
							backgroundColor: 'black',
							marginTop: '2px',
							marginBottom: '2px'
						}}
					/>

{/* commented after closing registrations
					<div className="content-tournament">
						<h2>Tournament Details:</h2>
						<br></br>
						<p className="home-p-l">
							<b>DATE:</b> 1 May 2024
						</p>
						<p>
							<b> TIME:</b> 8:30 AM to 12:30 PM & Prize distribution 3:45 PM to 5:30 PM
						</p>
						<p>
							<b>NUMBER OF ROUNDS:</b> Maximum 4
						</p>
						<p>
							<b>DURATION OF EACH ROUND:</b> 40 minutes (For details, see the{' '}
							<a href="#duration">duration</a> section.)
						</p>
						<b>CATEGORIES:</b>
						<div className="App">
							<header className="App-header">
								{/* Other components or content * /}
								<CategoriesTable />
							</header>
						</div>
						<p></p>
						<br></br>
						<p>
							<b>SNACKS:</b> Light snacks (Milo & Biscuits) will be provided to the players after
							the 2nd Round.
						</p>
						<p>
							<b>PAIRING SYSTEM:</b> Swiss system (multiple rounds for every player)
						</p>
						<p>
							<b>ENTRY FEE:</b> 25SGD (For payment details, see the <a href="#payments">payments</a>{' '}
							section.)
						</p>
						<b>ITEMS TO BRING TO THE CHESS TOURNAMENT:</b> <br></br>- Please bring your{' '}
						<b>own Chess Board.</b>
						<br></br>
						&nbsp; (This allows us to allocate more funds towards prizes rather than spending on
						chess boards for the tournament. We appreciate your understanding.)
						<br></br>- Using Chess Clock is optional.
						<br></br>
						<br></br>
						<b>SCHEDULE:</b>
						<div className="App">
							<header className="App-header">
								{/* Other components or content * /}
								<ScheduleTable />
							</header>
						</div>
						<br></br>
						<br></br>
						<p>
							<b>PRIZES:</b> <br></br>- Winner, Runner-up, and 3rd Place.
							<br></br>- Consolation prizes for special performances.
							<p>- Participation certificates for all players.</p>

							<b>VENUE:</b>
							<br></br>
							Spring Auditorium
							<br></br>2 Bukit Merah Central, Room Number P-303, Singapore 159835
							<br></br>
							<a href="https://maps.app.goo.gl/BuVhXB6WZyysW6g47" target="_blank">
								{' '}
								https://maps.app.goo.gl/BuVhXB6WZyysW6g47
							</a>
						</p>
						<p>
							For location details, see the <a href="#howToGetThere">How to get there</a> section.
						</p>
						<br></br>
						<p>
							<b>ENTRY TO THE TOURNAMENT HALL:</b> <br></br>
							Only players are allowed into the Tournament hall. Parents & visitors are not allowed.
							<br></br>
							(This is to reduce noise and distractions to the players. Seeking your understanding)
						</p>
					</div>

					<br></br>
						*/}
					<div className="content-tournament">
						<h2>Tournament Conditions and Regulations:</h2>
{/* commented after closing registrations 
						<p>
							<b>ENROLLMENT ACCEPTANCE:</b>
							<br />
							- Entries submitted after the closing date will not be accepted.
							<br />
							- The Organising Committee reserves the right to accept or reject any application
							without prejudice.
							<br />
						</p>
						<p>
							<b>CATEGORIES:</b>
							<br />
							In the event that there are fewer than 8 players in an age group, that group may be
							merged with another age group or cancelled. Enrolled participants will be notified in
							the case of any cancellations.
						</p>
						<p>
							<div id="payments">
								<b>ENTRY FEE PAYMENT PROCEDURE:</b>
							</div>
							<br />
							For Learn Chess Academy’s current students: There's no need to pay upfront. For those
							enrolled in the tournament, the fee will be included in the May chess classes
							invoice.”.
						</p>
						<p>
							For all other participants:
							<br />
							1. Please PayNow to LCA’s Mobile number +6597224377 &
							<br />
							2. Whatsapp the Student’s Full name & Father’s name to +6597224377
						</p>
						<p>
							<b>REFUND POLICY:</b>
							<br />
							- Once enrolled, the fee will not be refunded.
							<br />- Fees for withdrawals or absenteeism are non-refundable
						</p>
*/}						
						<p>
							<div id="duration">
								<b>DURATION:</b>
							</div>
							Each round lasts 40 minutes. If a game is not concluded within this timeframe, the
							arbiter will decide the winner based on material advantage or winning chances. In
							cases where both players have equal material and positional advantages, the game will
							be declared a draw. The arbiter’s decision is final.
						</p>
						<p>
							<div id="ZeroStart">
								<b>ZERO START RULE:</b>
								<br />
								- Participants should be seated at their designated places 10 minutes before the round
								begins.
								<br />- There is no grace period; a player not present at their position when the
								round starts will be considered to have lost that round.
							</div>
						</p>
						<p>
							<b>TOUCH AND MOVE:</b>
							<br />
							The 'touch and move' rule is strictly enforced, meaning if a piece is touched, it must
							be moved (unless it is an illegal move).
						</p>
						<p>
							<b>ILLEGAL MOVES:</b>
							<br />
							- Only 1 illegal move is allowed across all categories, except for the “Under 6”
							category.
							<br />
							- Upon an illegal move, if the opponent calls the arbiter, the player will receive a
							warning. A second offense after the warning will result in the opponent being declared
							the winner.
							<br />- 3 illegal moves are permitted for the "Under 6" category.
						</p>
						<p>
							<b>SUBMISSION OF RESULTS:</b>
							<br />
							- Once the game is finished, the players must be seated at their boards without
							disturbing the position & notify to the Organizers.
							<br />
							- Players must remain at their boards until the Organizer has recorded the result and
							asked them to leave.
							<br />
							- If the result is not recorded in this method, both the players will not be give any
							game points.
							<br />- Where there is a walkover, this shall be indicated on the Result Sheet.
						</p>
						<p>
							<b>RECORDING GAME:</b>
							<br />
							- Recording is not compulsory.
							<br />- But Players shall have no right of claim in the case of a dispute if he/she
							does not keep a proper record of their games.
						</p>
						<p>
							<b>RESOLUTION:</b>
							<br />
							- Any dispute arising from a game shall be referred to the Arbiter immediately.
							<br />- In case of any dispute, Arbiter's decision is final, and no further
							correspondences shall be entertained.
						</p>
						<p>
							<b>TIEBREAK:</b>
							<br />
							In case of a tie for a division title, the player with the higher tiebreak (in the
							following order) is declared as winner.
							<br />
							1. Play-off (if time permits),
							<br />
							2. Direct encounter
							<br />
							3. The greater number of wins
							<br />
							4. The greater number of games with Black (unplayed games shall be counted as played
							with White)
							<br />
							5. Buchholz Cut 1
							<br />
							6. Buchholz
							<br />
							7. Sonneborn-Berger
							<br />
							8. Lottery
						</p>
						<p>
							<b>ELECTRONIC DEVICES:</b>
							<br />
							- The use of mobile phones or ANY electronic devices is prohibited.
							<br />
							- All electronics will be switched off and kept in bags, and the bags will be stowed
							beneath the table during each round.
							<br />- Use of electronic devices and mobile phones are only allowed once players have
							exited the playing area.
						</p>
						<p>
							<b>OTHERS:</b>
							<br />
							- Only players are allowed within the playing area.
							<br />
							- Once players have completed their games, they are deemed as spectators and must
							leave the playing area.
							<br />- Non-players of the event (parents/accompanying persons/spectators, etc) are
							not allowed within the Playing Area.
						</p>
						<b>FACILITIES AT THE VENUE:</b>
						<br />
						- Drinking Water & washrooms are available at the premises.
						<br />
						- Unfortunately, Limited Seating is available for parents outside the tournament hall at
						the venue.
						<br />- "Sarawak Food Court" is a few blocks away for refreshments.
						<a href="https://maps.app.goo.gl/M7A6PvxRmk1quPgeA" target="_blank">
								{' '}
								https://maps.app.goo.gl/M7A6PvxRmk1quPgeA
							</a>						
						<br />- "Brinda" Indian restaurant is within walking distance. 
						<a href="https://maps.app.goo.gl/K1wrvQwnMbGr6t8G8" target="_blank">
								{' '}
								https://maps.app.goo.gl/K1wrvQwnMbGr6t8G8
							</a>						
						<br></br>
						<br></br>
{/* commented after closing registrations 						
						<p>
							<b>REGISTRATION PROCEDURE:</b>
							<br />
							1. Go to the website "http://www.LCA.sg/Tournament.html”
							<br />
							2. Click on the “Learn Chess Academy Tournament 2024 Enrolment Link”.
							<br />
							3. Provide the requested details & Submit the Form.
						</p>
							*/}
						<br></br>
						<br></br>
						<p>
							<hr
								style={{
									height: '5px',
									backgroundColor: 'black',
									marginTop: '2px',
									marginBottom: '2px'
								}}
							/>
							<br></br>
							<div id="howToGetThere">
								<h2>How to get there</h2>
								<p>
									<b>Venue :</b>
									<br />
									Spring Auditorium
									<br />
									2 Bukit Merah Central, Room Number P-301
									<br />
									Singapore 159835
								</p>
								<iframe
									id="map"
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.815990262515!2d103.81117491133845!3d1.2843267986980704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1bd164eb278d%3A0xa2366fae5a6cd63d!2sSpring%20Singapore!5e0!3m2!1sen!2ssg!4v1711477435194!5m2!1sen!2ssg"
									title="Spring Auditorium google map"
									width="600"
									height="450"
									style={{ border: 0 }}
									allowFullScreen // Implies allowFullScreen={true}
									loading="lazy"
									referrerPolicy="no-referrer-when-downgrade"
								></iframe>
							</div>

							<p>
								<br />
								<br></br>
								<br></br>
								<b>BY MRT TRAIN & BUS:</b>
								<br />
								1. Outram Park MRT - Exit H
								<br />
								2. Walk to the Bus stop (05069) at New Bridge Road.
								<br />
								3. Take Bus 147, 961
								<br />
								4. Alight at Spring Auditorium (9 stops)
								<br />
							</p>
							<br></br>
							<p>
								<b>DIRECTIONS AT THE BUILDING :</b>
								<br />
								1. Reach Building entrance
								<div className="image-container">
									<img src={Building} alt="building" />
									<br />
									<br /> <br />
								</div>
								<br />
								2. While facing the building, go towards left side towards “In” green color board at
								the top in between 4 steel pillars.
								<div className="image-container">
									<br />
									<br /> <br />
									<img src={Entrance} alt="entrace" />
								</div>
								<br />
								3. Go towards “Podium” on the left side and access the lift to 3rd floor.
								<br />
								<div className="image-container">
									<img src={Podium} alt="podium" />
									<br />
									<br />
								</div>{' '}
								<br />
								4. Look for hall P301 in
								<div className="image-container">
									<br />
									<br />
									<br />
									<img src={Hall} alt="hall image" />
								</div>{' '}
								<br />
							</p>
							<p>
								<b>Car Parking :</b>
								<br />- Parking is available at the venue and is charged hourly
								<div className="image-container">
									<img src={Parking} alt="parking" />
								</div>
								<br />
							</p>
						</p>
						<p>
							<b>Support:</b>
							<br />
							For any inquiries or further information, please contact Coach <b>Murali</b> at +65
							9722 4377 or Coach <b>Gopi</b>.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Tournament;
