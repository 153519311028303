import React, { useState } from 'react';
import './AnnualMeet.css';
import KVRaoGaru from './../assets/KVRaoGaru.jpeg';
import SriAnuj from './../assets/SriAnuj.png';

const AnnualMeet: React.FC = () => {
	const [showInnovations, setShowInnovations] = useState(true);
	const [showFutureDirections, setShowFutureDirections] = useState(true);
	const [showEvaluatingStudentProgress, setShowEvaluatingStudentProgress] = useState(true);
	const [showParentalInvolvement, setShowParentalInvolvement] = useState(true);
	const [showAdditionalSupport, setShowAdditionalSupport] = useState(true);
	const [showFurtherImprovement, setShowFurtherImprovement] = useState(true);

	return (
		<div>
			<div className="container-annual">
				<h1>
					<u>LCA Annual Fun Meet 2024</u>
				</h1>
				<br></br>
				<span className="indent-span" style={{ fontSize: 'large' }}>
					{' '}
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Join
					us for a prestigious gathering at the 2024 Learn Chess Academy's Annual Celebration, where
					we celebrate our students' achievements and unveil the latest in chess coaching
					excellence. This event is a testament to our commitment to nurturing talent and fostering
					a supportive community.
				</span>
				<br></br>
				<br></br>
				<br></br>
				<h2 className="event-highlights">
					Date: <span style={{ fontSize: 'smaller' }}>&nbsp; 1 May 2024</span>
				</h2>
				<h2 className="event-highlights">
					Time: <span style={{ fontSize: 'smaller' }}>&nbsp; 4 PM to 5:30 PM</span>
				</h2>
				<h2 className="event-highlights">Event Highlights</h2> {/* Subtitle added */}
				<div className="tournament-container indent-section">
					<div>
						<h3 style={{ display: 'inline' }}>Honored Guest:&nbsp;</h3>
						<h2 style={{ display: 'inline' }} className="event-highlights">Sri K.V. Rao</h2>
					</div>
					{/* Increase marginTop for spacing */}
						<span className="indent-span" style={{ fontSize: 'medium' }}>
							* President, Singapore Indian Fine Arts Society (SIFAS)
						</span>
						<br></br>
						<span className="indent-span" style={{ fontSize: 'medium' }}>
							* Chairman, Tata International Singapore, and Former Resident Director ASEAN, Tata Sons. 
						</span>
						<br></br>
						<span className="indent-span" style={{ fontSize: 'medium' }}>
							* Term Trustee, SINDA 
						</span>

					<div className="kvraoimage-container" style={{ paddingLeft: '50px', marginBottom: '20px', marginTop: '20px' }}>
						<img src={KVRaoGaru} alt="K.V. Rao" />
					</div>


						<span className="indent-span" style={{ fontSize: 'medium' }}>
						* Attendees will have the unique opportunity to be motivated by the insightful speech delivered by K.V. Rao.
						</span>
						<br></br>
						<span className="indent-span" style={{ fontSize: 'medium' }}>
						* Champions of the tournament will have the prestigious opportunity to have their prizes awarded by K.V. Rao.
						</span>
						<br></br>
						<br></br>
						<br></br>

					<div>
						<h3 style={{ display: 'inline' }}>Distinguished Guest:&nbsp;</h3>
						<h2 style={{ display: 'inline' }} className="event-highlights">Sri Anuj Khanna Sohum</h2>
					</div>
					{/* Increase marginTop for spacing */}
						<span className="indent-span" style={{ fontSize: 'medium' }}>
							* Founder & CEO of Affle Group
						</span>
						<br></br>

					<div className="kvraoimage-container" style={{ paddingLeft: '50px', marginBottom: '20px', marginTop: '20px' }}>
						<img src={SriAnuj} alt="Sri Anuj" />
					</div>


						<span className="indent-span" style={{ fontSize: 'medium' }}>
						* Participants will experience the unique benefit of hearing a few words from Sri Anuj Khanna Sohum.
						</span>
						<br></br>
						<span className="indent-span" style={{ fontSize: 'medium' }}>
						* Students will have the fortune of receiving special awards from Sri Anuj Khanna Sohum.
						</span>
						<br></br>
						<br></br>
						<br></br>



					<h3>Keynote Presentations</h3>
					<div>
						<p
							className="clickable indent-innovations"
							onClick={() => setShowInnovations(!showInnovations)}
						>
							{showInnovations ? '- ' : '+ '}Recent Innovations in LCA Coaching Methodology
						</p>
						{showInnovations && (
							<div className="presentation-detail indent-details orange-text">
								Delve into our enhanced coaching strategies, including guided play that matches
								students with peers of equal skill for meaningful game analysis.
							</div>
						)}
					</div>
					<div>
						<p
							className="clickable indent-future"
							onClick={() => setShowFutureDirections(!showFutureDirections)}
						>
							{showFutureDirections ? '- ' : '+ '}Future Directions in LCA Coaching
						</p>
						{showFutureDirections && (
							<div className="presentation-detail indent-details orange-text">
								Explore upcoming enhancements such as AI-based materials, digital progress tracking,
								and real-time class updates.
							</div>
						)}
					</div>
					<div>
						<p
							className="clickable indent-Evaluating"
							onClick={() => setShowEvaluatingStudentProgress(!showEvaluatingStudentProgress)}
						>
							{showEvaluatingStudentProgress ? '- ' : '+ '}Evaluating Student Progress
						</p>
						{showEvaluatingStudentProgress && (
							<div className="presentation-detail indent-details orange-text">
								Understand your child's current chess proficiency and their recent advancements.
							</div>
						)}
					</div>
					<div>
						<p
							className="clickable indent-innovations"
							onClick={() => setShowParentalInvolvement(!showParentalInvolvement)}
						>
							{showParentalInvolvement ? '- ' : '+ '}Parental Involvement
						</p>
						{showParentalInvolvement && (
							<div className="presentation-detail indent-details orange-text">
								Insights on the pivotal role parents play, including monitoring assignment
								completion and providing motivational support.
							</div>
						)}
					</div>
					<div>
						<p
							className="clickable indent-innovations"
							onClick={() => setShowAdditionalSupport(!showAdditionalSupport)}
						>
							{showAdditionalSupport ? '- ' : '+ '}Additional Support from LCA
						</p>
						{showAdditionalSupport && (
							<div className="presentation-detail indent-details orange-text">
								Discover our extended support services upon request, including scheduling
								assistance, study and memory techniques, and motivational strategies.
							</div>
						)}
					</div>
					<div>
						<p
							className="clickable indent-innovations"
							onClick={() => setShowFurtherImprovement(!showFurtherImprovement)}
						>
							{showFurtherImprovement ? '- ' : '+ '}Keys to Further Improvement
						</p>
						{showFurtherImprovement && (
							<div className="presentation-detail indent-details orange-text">
								Emphasizing the importance of physical fitness, mental focus, and consistent
								practice for advancing in chess.
							</div>
						)}
					</div>
					<br></br>
					<br></br>
					<h3>Fun games</h3>
					<span className="indent-span" style={{ fontSize: 'medium' }}>
						* Gear up for an unforgettable day of fun games at our Annual Meet, designed to
						challenge the mind and delight the heart!
					</span>
					<br></br>
					<br></br>
					<br></br>
					<h3>Talent Showcase</h3>
					<span className="indent-span" style={{ fontSize: 'medium' }}>
						* Skit on Chess, Rubiks Cube, Magic Squares, Calendar, Tables, Summing up, Memory etc.
					</span>
					<br></br>
					<br></br>
					<br></br>
					<h3>Special Awards Distribution</h3>
					<p>
						<span className="indent-span" style={{ fontSize: 'medium' }}>
							{' '}
							* Good Sitting Posture
						</span>
					</p>
					<p>
						<span className="indent-span" style={{ fontSize: 'medium' }}>
							{' '}
							* Immediate Completion of Assignments
						</span>
					</p>
					<p>
						<span className="indent-span" style={{ fontSize: 'medium' }}>
							{' '}
							* Consistent Completion of Assignments
						</span>
					</p>
					<p>
						<span className="indent-span" style={{ fontSize: 'medium' }}>
							{' '}
							* Regularity in Having Classes
						</span>
					</p>
					<p>
						<span className="indent-span" style={{ fontSize: 'medium' }}>
							{' '}
							* Neatness and good presentation of Assignments
						</span>
					</p>
					<br></br>
					<h3>Tournament Awards Ceremony</h3>
					<p>
						<span className="indent-span" style={{ fontSize: 'medium' }}>
							{' '}
							* Awards Distribution to the tournament winners
						</span>
					</p>
					<p>
						<span className="indent-span" style={{ fontSize: 'medium' }}>
							{' '}
							* Certificate Distribution to all tournament participants
						</span>
					</p>
				</div>
				<h2 className="event-highlights">Benefits for Attendees</h2> {/* Subtitle added */}
				<div className="tournament-container indent-section">
					{' '}
					{/* Indentation for the whole section */}
					<h4>
						👉 &nbsp; Each current student of LCA will receive a special gift hamper as a token of
						our appreciation.
					</h4>
					<h4>👉 &nbsp; Engage in surprise quizzes with the chance to win exciting prizes.</h4>
					<h4>👉 &nbsp; Be inspired by the talent show.</h4>
					<h4>👉 &nbsp; Join us in applauding the achievements of our award-winning students.</h4>
				</div>
				<h2 className="event-highlights">Refreshments</h2> {/* Subtitle added */}
				<div className="tournament-container indent-section">
					{' '}
					{/* Indentation for the whole section */}
					<h4>Snacks and drinks will be available</h4>
				</div>
				<h2 className="event-highlights">Venue</h2> {/* Subtitle added */}
				<div className="tournament-container indent-section">
					{' '}
					{/* Indentation for the whole section */}
					<h4>
						Spring Auditorium
						<br></br>2 Bukit Merah Central, Room Number P-303,
						<br></br>
						Singapore 159835
						<br></br>
						<br></br>
						<br></br>
						<iframe
							id="map"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.815990262515!2d103.81117491133845!3d1.2843267986980704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1bd164eb278d%3A0xa2366fae5a6cd63d!2sSpring%20Singapore!5e0!3m2!1sen!2ssg!4v1711477435194!5m2!1sen!2ssg"
							title="Spring Auditorium google map"
							width="600"
							height="450"
							style={{ border: 0 }}
							allowFullScreen // Implies allowFullScreen={true}
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						></iframe>
						<br></br>
						<br></br>
						<br></br>
						<br></br>
						<p>For more details, see the "How to get there section" in the Tournament page.</p>{' '}
					</h4>
				</div>
			</div>
		</div>
	);
};

export default AnnualMeet;
