import React from 'react';
import './App.css';
import Footers from './Pages/Footers';
import { BrowserRouter as Router, Route, Routes, useParams, useLocation } from 'react-router-dom';
import Home from './Components/Home';
import About from './Components/About';
import Methodology from './Components/Methodology';
import FAQs from './Components/FAQs';
import Contact from './Components/Contact';
import Head from './Pages/Head';
import { Drawer } from 'antd';
import { useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { relative } from 'path';

import { Provider } from 'react-redux';
import { store } from './app/store';
import Login from './Components/admin/Login';
import Register from './Components/admin/Register';
import Approach from './Components/Approach';
import Cognition from './Components/Cognition';
import Accomplishments from './Components/Accomplishments';
import Terms from './Components/Terms';
import GettingStarted from './Components/GettingStarted';
import AttendClasses from './Components/AttendClasses';
import InitialSetup from './Components/InitialSetup';
import FreeDemo from './Components/FreeDemo';
import VideosForm from './Components/VideosForm';
import Tournament from './Components/Tournament';
import AnnualMeet from './Components/AnnualMeet';
import Hybrid from './Components/Hybrid';

function App() {
	return (
		<>
			<Provider store={store}>
				<Router>
					<div>
						<Head />
					</div>

					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/tournament" element={<Tournament />} />
						<Route path="/annual" element={<AnnualMeet />} />
						<Route path="/hybrid" element={<Hybrid />} />
						<Route path="/about" element={<About />} />
						<Route path="/methodology" element={<Methodology />} />
						<Route path="/approach" element={<Approach />} />
						<Route path="/accomplishments" element={<Accomplishments />} />
						<Route path="/cognition" element={<Cognition />} />
						<Route path="/demo" element={<FreeDemo />} />
						<Route path="/contactUs" element={<Contact />} />
						<Route path="/terms" element={<Terms />} />
						<Route path="/gettingStarted" element={<GettingStarted />} />
						<Route path="/attendclasses" element={<AttendClasses />} />
						<Route path="/InitialSetup" element={<InitialSetup />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/login" element={<Login />} />
						<Route path="/admin" element={<VideosForm />} />
					</Routes>
					<Footers />
				</Router>
			</Provider>
		</>
	);
}

export default App;
